<template>
  <dialogComp
    :title="titleD"
    width="640px"
    :isShow="isShow"
    @close="close"
    @sure="sure"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="100px"
    >
      <div class="flex">
        <inputFormComp
          label="用户名:"
          prop="loginName"
          :hval="formData.loginName"
          @blur="loginNameBlur"
          placeholder="请输入用户名"
        />
        <inputFormComp
          label="姓名:"
          prop="realName"
          :hval="formData.realName"
          @blur="realNameBlur"
          placeholder="请输入姓名"
        />
      </div>
      <div class="flex">
        <inputFormComp
          label="手机号:"
          prop="mobile"
          :hval="formData.mobile"
          @blur="mobileBlur"
          placeholder="请输入手机号"
        />
        <inputFormComp
          label="密码:"
          :prop="title === 'add' ? 'password' : ''"
          :hval="formData.password"
          @blur="passwordBlur"
          placeholder="请输入密码"
        />
      </div>
      <div class="flex">
        <businessRoles
          label="业务角色:"
          :hval="formData.businessRole"
          @change="businessRoleChange"
        />
        <authRoles
          label="权限角色:"
          prop="authorityRole"
          :hval="formData.authorityRole"
          @change="authorityRoleChange"
        />
      </div>
      <div class="flex">
        <el-form-item v-if="isSelectSale" label="战区:" prop="city">
          <salesArea
          :isLoginRole="false"
            :hval="formData.city"
            @change="cityChange"
          />
        </el-form-item>
        <radioFormComp
          style="width: 52%"
          label="性别:"
          prop="sex"
          :radioList="radioList"
          :hval="formData.sex"
          @change="sexChange"
        />
      </div>
    </el-form>
  </dialogComp>
</template>
<script>
import businessRoles from '../componets/businessRoles'
import authRoles from '@/views/components/authRoles'
import salesArea from '@/views/components/salesArea'
import { userAdd, userEdit, userDetail } from '@/api'
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'add'
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  components: { businessRoles, authRoles, salesArea },
  data () {
    const checkMobile = (rule, val, cb) => {
      var Reg = /^1[3456789]\d{9}$/
      if (Reg.test(val)) {
        return cb()
      }
      cb(new Error('手机号码格式不正确'))
    }
    return {
      radioList: [
        { label: '男', value: 1 },
        { label: '女', value: 2 }
      ],
      formData: {
        loginName: '',
        realName: '',
        password: '',
        mobile: '',
        sex: 1,
        businessRole: [],
        authorityRole: '',
        salesAreaId: '',
        salesAreaChiId: '',
        city: []
      },
      rules: {
        realName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        loginName: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' }
        ],
        businessRole: [
          { required: true, message: '请选择业务角色', trigger: 'change' }
        ],
        authorityRole: [
          { required: true, message: '请选择权限角色', trigger: 'change' }
        ],
        city: [
          { required: true, message: '请选择战区', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    titleD () {
      return this.title === 'add' ? '新增用户' : '编辑用户'
    },
    isSelectSale () { // 业务角色是否选择销售
      return this.formData.businessRole.includes('sale')
    }
  },
  watch: {
    isShow (val) {
      if (val && this.title === 'edit') {
        userDetail({ id: this.info.id }).then(res => {
          if (res.code === '1') {
            const {
              realName,
              loginName,
              mobile,
              password,
              sex,
              businessRole,
              authorityRole,
              salesAreaId,
              salesAreaChiId
            } = res.data
            this.formData.realName = realName
            this.formData.loginName = loginName
            this.formData.mobile = mobile
            this.formData.password = password
            this.formData.sex = sex
            this.formData.businessRole = businessRole ? businessRole.split(',') : []
            this.formData.authorityRole = authorityRole
            this.formData.salesAreaId = salesAreaId
            this.formData.salesAreaChiId = salesAreaChiId
            this.handleCity(salesAreaId, salesAreaChiId)
          }
        })
      }
    }
  },
  methods: {
    handleCity (salesAreaId, salesAreaChiId) {
      if (salesAreaId && salesAreaChiId) {
        this.formData.city = [salesAreaId, salesAreaChiId]
      } else if (salesAreaId && !salesAreaChiId) {
        this.formData.city = [salesAreaId]
      } else {
        this.formData.city = []
      }
      console.log(this.formData.city)
    },
    loginNameBlur (val) {
      this.formData.loginName = val
    },
    realNameBlur (val) {
      this.formData.realName = val
    },
    mobileBlur (val) {
      this.formData.mobile = val
    },
    passwordBlur (val) {
      this.formData.password = val
    },
    businessRoleChange (val) {
      this.formData.businessRole = val
    },
    authorityRoleChange (val) {
      this.formData.authorityRole = val
    },
    cityChange (val) {
      this.formData.city = val
      if (val.length === 1) {
        this.formData.salesAreaId = val[0]
        this.formData.salesAreaChiId = ''
      } else if (val.length === 2) {
        this.formData.salesAreaId = val[0]
        this.formData.salesAreaChiId = val[1]
      } else {
        this.formData.salesAreaId = ''
        this.formData.salesAreaChiId = ''
      }
    },
    sexChange (val) {
      this.formData.sex = val
    },
    close () {
      this.$refs.formData.resetFields()
      this.formData.businessRole = []
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const {
            loginName,
            realName,
            password,
            mobile,
            sex,
            businessRole,
            authorityRole,
            salesAreaId,
            salesAreaChiId
          } = this.formData
          const params = {
            loginName,
            realName,
            password,
            mobile,
            sex,
            businessRole: businessRole.join(','),
            authorityRole,
            salesAreaId,
            salesAreaChiId
          }
          if (this.title === 'add') {
            userAdd(params).then(_ => {
              this.$message.success('添加成功')
              this.close()
            })
          } else {
            userEdit({ ...params, id: this.info.id }).then(_ => {
              this.$message.success('编辑成功')
              this.close()
            })
          }
        }
      })
    }
  }
}
</script>
