<template>
  <el-form-item :label="label" :prop="prop">
    <el-select
      v-model="businessRole"
      size="small"
      multiple
      placeholder="请选择业务角色"
      @change="change"
    >
      <el-option
        v-for="item in businessRoleList"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
  </el-form-item>
</template>
<script>
import { businessRoleListToSys } from '@/utils/constData'
export default {
  props: {
    prop: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    hval: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      businessRole: []
    }
  },
  computed: {
    businessRoleList () {
      return businessRoleListToSys
    }
  },
  watch: {
    hval: {
      immediate: true,
      handler (val) {
        this.businessRole = val
      }
    }
  },
  methods: {
    change () {
      this.$emit('change', this.businessRole)
    }
  }
}
</script>
<style lang='scss' scoped>
</style>
