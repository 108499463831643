<template>
      <div  style="margin-top:20px">
        <div class="search_flex">
          <div class="search_left">
            <inputComp
              placeholder="用户名"
              :hval="loginName"
              @blur="loginNameBlur"
            />
             <inputComp
              placeholder="姓名"
              :hval="realName"
              @blur="realNameBlur"
            />
            <inputComp placeholder="手机号" :hval="mobile" @blur="mobileBlur" />
            <selectComp
              v-if="tabType === 'backstage'"
              placeholder="业务角色"
              :data="businessRoleListToSys"
              :hval="businessRole"
              @change="businessRoleChange"
            />
            <selectComp
             v-if="tabType === 'backstage'"
              placeholder="系统角色"
              :data="authorityRoleList"
              :hval="authorityRole"
              @change="authorityRoleChange"
            />
            <selectComp
              placeholder="用户状态"
              :data="userStatusList"
              :hval="status"
              @change="statusChange"
            />
            <btnGroup @search="search" @reset="reset" />
            <btnComp @click="add('add')" v-permission="'新增'">新 增</btnComp>
            <btnComp
              :disabled="allotDisabled"
              btnType="plain"
              @click="batchHandle('批量锁定', 2)"
              v-permission="'批量锁定'"
              >批量锁定</btnComp
            >
            <btnComp
              :disabled="allotDisabled"
              btnType="plain"
              @click="batchHandle('批量恢复', 1)"
              v-permission="'批量恢复'"
              >批量恢复</btnComp
            >
            <btnComp
              :disabled="allotDisabled"
              btnType="plain"
              @click="batchHandle('批量删除', 3)"
              v-permission="'批量删除'"
              >批量删除</btnComp
            >
          </div>
        </div>
        <tableComp
          :data="tableData"
          :col-configs="theadName"
          isShowCheckBox
          :total="total"
          :currentPage="curPage"
          :pageSize="pageSize"
          @select="select"
          @selectAll="selectAll"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        >
          <el-table-column slot="authorityRoleName" label="角色" >
            <template v-slot="scope">
              <div>{{scope.row.authorityRoleName}}</div>
            </template>
          </el-table-column>
          <el-table-column slot="area" label="区域" >
            <template v-slot="scope">
              <div v-if="scope.row.salesAreaName || scope.row.salesAreaChiName">{{scope.row.salesAreaName}} {{ scope.row.salesAreaChiName?('/'+scope.row.salesAreaChiName): ''}}</div>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column slot="operate" label="操作" fixed="right">
            <template v-slot="scope">
              <btnComp btnType="text" @click="add('edit', scope.row)" v-permission="'编辑'"
                >编辑</btnComp
              >
            </template>
          </el-table-column>
        </tableComp>
        <add :isShow="addShow" @close="close" :title="title" :info="info" />
        <dialogComp
          title="提示"
          :isShow="hintShow"
          @close="close"
          @sure="hintSure"
        >
          <p>{{ `确定${hintTitle}吗？` }}</p>
        </dialogComp>
      </div>
</template>
<script>
import add from './add'
import { userList, userUpdateStatus, rolesList } from '@/api'
import { businessRoleListToSys, userStatusList } from '@/utils/constData'
export default {
  name: '',
  props: {
    tabType: {
      type: String,
      default: 'backstage'
    }
  },
  components: { add },
  data () {
    return {
      addShow: false,
      hintShow: false,
      selectIds: [],
      hintTitle: '',
      curPage: 1,
      pageSize: 10,
      total: 0,
      loginName: '',
      realName: '',
      mobile: '',
      status: '',
      businessRole: '',
      authorityRole: '',
      authorityRoleList: [],
      title: '',
      info: {},
      tableData: [],
      theadName: [
        { prop: 'loginName', label: '用户名' },
        { prop: 'realName', label: '姓名' },
        { prop: 'statusName', label: '用户状态' },
        { prop: 'workStatusName', label: '工作状态' },
        { prop: 'mobile', label: '手机号' },
        { slot: 'authorityRoleName' },
        // { prop: 'authorityRoleName', label: '角色' },
        { prop: 'businessRoleName', label: '业务角色' },
        { slot: 'area' },
        { slot: 'operate' }
      ]
    }
  },
  computed: {
    allotDisabled () {
      return !(this.selectIds.length > 0)
    },
    businessRoleListToSys () {
      return businessRoleListToSys
    },
    userStatusList () {
      return userStatusList
    }
  },
  mounted () {
    this.getList()
    this.getAuthorityRoleList()
  },
  methods: {
    getList () {
      const params = {
        roleType: this.tabType === 'backstage' ? 1 : 2, // 角色类别，1：系统用户，2：商家用户
        curPage: this.curPage,
        pageSize: this.pageSize,
        loginName: this.loginName,
        realName: this.realName,
        mobile: this.mobile,
        status: this.status,
        businessRole: this.businessRole,
        authorityRole: this.authorityRole,
        storeId: this.storeId
      }
      userList(params).then(res => {
        if (res.code === '1') {
          this.tableData = res.data.list
          this.total = res.data.totalCount
        }
      })
    },
    getAuthorityRoleList () {
      const params = {
        curPage: 1,
        pageSize: 100,
        type: 1 // 角色类别，1：系统角色（默认），2：商家角色
      }
      rolesList(params).then((res) => {
        if (res.code === '1') {
          this.authorityRoleList = res.data.list.map(item => ({ value: item.id, label: item.name }))
        }
      })
    },
    loginNameBlur (val) {
      this.loginName = val
    },
    realNameBlur (val) {
      this.realName = val
    },
    mobileBlur (val) {
      this.mobile = val
    },
    businessRoleChange (val) {
      this.businessRole = val
    },
    authorityRoleChange (val) {
      this.authorityRole = val
    },
    statusChange (val) {
      this.status = val
    },
    search () {
      this.curPage = 1
      this.getList()
    },
    add (val, info) {
      this.title = val
      if (val === 'edit') {
        this.info = info
      }
      this.addShow = true
    },
    select (val) {
      this.selectIds = val.map(item => item.id)
    },
    selectAll (val) {
      this.selectIds = val.map(item => item.id)
    },
    batchHandle (val, status) {
      this.hintTitle = val
      this.batchHandlestatus = status
      this.hintShow = true
    },
    hintSure () {
      const params = {
        ids: this.selectIds,
        status: this.batchHandlestatus
      }
      userUpdateStatus(params).then(res => {
        if (res.code === '1') {
          this.$message.success(`${this.hintTitle}成功`)
          this.close()
        }
      })
    },
    close () {
      this.selectIds = []
      this.addShow = false
      this.hintShow = false
      this.getList()
    },
    reset () {
      this.curPage = 1
      this.loginName = ''
      this.realName = ''
      this.mobile = ''
      this.businessRole = ''
      this.authorityRole = ''
      this.status = ''
      this.getList()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>
