<template>
  <div class="layout_common">
    <div class="main_box">
      <div class="main_box_con" style="padding-top:10px">
        <el-tabs class="tabs" v-model="activeName" style="padding-left:0">
          <el-tab-pane label="后台人员" name="backstage">
            <comp :tabType="activeName" v-if="activeName==='backstage'" />
          </el-tab-pane>
          <el-tab-pane label="商家人员" name="store">
            <comp :tabType="activeName" v-if="activeName==='store'" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import comp from './comp'
export default {
  components: {
    comp
  },
  data () {
    return {
      activeName: 'backstage'
    }
  }
}
</script>
