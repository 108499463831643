<template>
  <el-form-item :label="label" :prop="prop">
    <el-select
      v-model="authorityRole"
      size="small"
      placeholder="请选择权限角色"
      @change="change"
    >
      <el-option
        v-for="item in authorityRoleList"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      >
      </el-option>
    </el-select>
  </el-form-item>
</template>
<script>
import { rolesList } from '@/api'
export default {
  props: {
    prop: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    // 角色类别，1：系统角色（默认），2：商家角色
    roleType: {
      type: Number,
      default: 1
    },
    hval: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      authorityRole: '',
      authorityRoleList: []
    }
  },
  watch: {
    hval (val) {
      this.authorityRole = val
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      rolesList({ curPage: 1, pageSize: 100, status: 1, type: this.roleType }).then((res) => {
        if (res.code === '1') {
          this.authorityRoleList = res.data.list.map((item) => ({
            id: item.id,
            name: item.name
          }))
        }
      })
    },
    change () {
      this.$emit('change', this.authorityRole)
    }
  }
}
</script>
